




























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      item: null,
      currentTab: "",
      tabs: {
        orders: {
          icon: "fas fa-shopping-cart",
          label: "Đơn hàng",
          condition() {
            return userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
              "vdl",
              "vd",
              "telesale",
              "cdc",
              "qlk",
              "ksnb",
              "ksnbl",
              "design",
              "content",
              "k",
            ]);
          },
        },
        statistics: {
          icon: "fas fa-chart-line",
          label: "Thống kê",
          condition() {
            return (
              userManager.checkRole(["vd", "vdl"]) ||
              !userManager.checkRole(["qlk"], true)
            );
          },
        },
        product_combos: {
          icon: "fas fa-cubes",
          label: "Combo sản phẩm",
          condition() {
            return userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
              "vdl",
              "vd",
            ]);
          },
        },
        mkt_links: {
          icon: "mdi-link-variant",
          label: "MKT Link",
          condition() {
            return userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
              "ksnb",
              "ksnbl",
            ]);
          },
        },
        mkt_costs: {
          icon: "fas fa-money-check-alt",
          label: "Chi phí MKT",
          condition() {
            return userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
              "ksnb",
              "ksnbl",
            ]);
          },
        },
        shops: {
          icon: "mdi-store",
          label: "Shop",
          condition({ item }) {
            return (
              item &&
              item.tmdt &&
              userManager.checkRole([
                "kt",
                "mkt",
                "tp",
                "rd",
                "rdl",
                "ksnb",
                "ksnbl",
              ])
            );
          },
        },
        warehouse_exports: {
          icon: "fas fa-file-export",
          label: "Xuất kho",
          condition() {
            return userManager.checkRole(["kt", "qlk", "vd", "vdl"]);
          },
        },
      },
    };
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
  },
});
